<template>
  <v-container>
    <v-form ref="form" v-model="valid">
      <v-card
        class="pa-md-2 mx-lg-auto"
        outlined
      >

        <table width="100%">
          <tr>
            <td>Hal</td>
            <td>:</td>
            <td class="subtitle-2"><span v-if="dataSurat">{{ dataSurat.perihalSurat ? dataSurat.perihalSurat : '-'}}</span></td>
          </tr>
          <tr>
            <td >Tanggal</td>
            <td>:</td>
            <td class="subtitle-2"><span v-if="dataSurat">{{ dataSurat.st_dateStart ? dataSurat.st_dateStart : '-' | formatDate}}</span> <span> S/D {{ dataSurat.st_dateEnd ? dataSurat.st_dateEnd : '-' | formatDate}}</span></td>
          </tr>
        </table>
        <v-divider></v-divider>

        <v-row>

           <v-col
          cols="12"
            md="12"
            style="font-size:18px !important;"
          >

          <v-combobox
              v-model="arrhruserpositions_to"
              :items="dataListUserposall"
              label="DAFTAR NAMA:"
              multiple
              chips
              @change="cekStatusYth()"
              item-value="value"
              item-text="text"
              :disabled="isEdit"
              counter
                  :rules="[rules.maxlength]"
            ></v-combobox>


          </v-col>
        </v-row>
        <v-row>
          <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="daftarnamaForm.unit_instansi"
                  label="UNIT / INSTANSI"
                  outlined
                  :disabled="isEdit"
                  counter
                  :rules="[rules.maxlength]"
                ></v-text-field>  
              </v-col>

            <v-col
                cols="12"
                md="1"
              >
              &nbsp;
            </v-col>
           <v-col
              cols="12"
              md="4"
            >
              <v-dialog
                  ref="SelRefTglStart"
                  v-model="selTglStart"
                  :return-value.sync="daftarnamaForm.dateStart"
                  persistent
                  width="290px"
                >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="daftarnamaForm.dateStart"
                    label="Tgl Mulai"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    :rules="[rules.required]"
                    outlined
                    @change="Validation($event)"
                  ></v-text-field>
                </template>
                  <v-date-picker 
                  v-model="daftarnamaForm.dateStart"
                  >
                  <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="selTglStart = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.SelRefTglStart.save(daftarnamaForm.dateStart)">OK</v-btn>
                  </v-date-picker>
                  
              </v-dialog>
              
            </v-col>
            <v-col
                cols="12"
                md="2"
              >
              <div class="d-flex justify-center align-center">S/D</div>
            </v-col>

          <v-col
              cols="12"
              md="4"
            >
              <v-dialog
                  ref="SelRefTglEnd"
                  v-model="selTglEnd"
                  :return-value.sync="daftarnamaForm.dateEnd"
                  persistent
                  width="290px"
                >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="daftarnamaForm.dateEnd"
                    label="Tgl Selesai"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    :rules="[rules.required]"
                    outlined
                    @change="Validation($event)"
                  ></v-text-field>
                </template>
                  <v-date-picker 
                  v-model="daftarnamaForm.dateEnd"
                  >
                  <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="selTglEnd = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.SelRefTglEnd.save(daftarnamaForm.dateEnd)">OK</v-btn>
                  </v-date-picker>
                  
              </v-dialog>
              
            </v-col>
            <v-col
                cols="12"
                md="1"
              >
              &nbsp;
            </v-col>

        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn
              color="warning"
              class="mr-4"
              @click="resetForm()"
            >
              Reset Form
            </v-btn>
            <v-btn
              color="grey"
              class="mr-4"
              @click="doCancel()"
            >
              Batal
            </v-btn>

            <v-btn :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveForm()"
            >
              Simpan
            </v-btn>
          
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <span style="font-size:8px;">{{idLetter}}</span>

    <v-snackbar
        v-model="toast.show"
        :timeout="toast.timeout"
      >
        {{ toast.text }}
        <v-btn
          :color="toast.color"
          text
          @click="toast.show = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      

      <v-dialog v-model="dialogConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin data tersebut sudah benar?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogConfirm = false">Batal</v-btn>
            <v-btn color="blue darken-1" @click="submitForm()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</v-container>
</template>

<script>
import secureStorage from '@/utils/secureStorage'
import { splitComponentsByComma } from '@/utils/index'
import { insertDaftarPegawaiST, updateDaftarPegawaiST } from '@/api/daftarpegawaiST'
import { _GLOBAL } from '@/api/server'
import moment from 'moment'
import axios from 'axios'

const MsPegawaiTugas = {
  id: 0,
  datasurat: 0,
  dateStart: '',
  dateEnd: '',
  fullday: 0,
  comment: '',
  isRemoved: 0,
  unit_instansi: '',
  hruserpositions_Creator: '',
  hruserpositions_pegawai: '',
  hruserpositions_approver: '',
  daftarNama: ''
}

export default {
  name: 'FormAddPegawai',
  components: {
  },
  props: {
    dataSurat: {
      type: Object,
      default: () => {}
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    seldaftarnama: {
      type: Object,
      default: () => {}
    },
  },
  data () {
    
    return {
      daftarnamaForm: Object.assign({}, MsPegawaiTugas),
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      valid: false,
      rules: {
        required: v => !!v || 'Required.',
        maxlength: v => v.length <= 240 || 'Max 240 characters',
      },
      isLoading: false,
      dialogConfirm: false,
      dataListUserposall: [],
      currrole: [],
      arrhruserpositions_to: [],
      idLetter: '',
      selTglStart: false,
      selTglEnd: false,
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      var sdate = moment(value).format('DD MMM YYYY')
      return sdate
    }
  },
  created () {
    const vm = this
    vm.currrole = secureStorage.getItem('currRole')
    // console.log(vm.currrole)
    vm.arrhruserpositions_to = []
    vm.daftarnamaForm = Object.assign({}, MsPegawaiTugas)

    vm.parseUser()
  },
  destroyed () {
  },
  mounted () {
    const vm = this
    
    if(vm.dataSurat){
      vm.idLetter = vm.dataSurat.id
      vm.daftarnamaForm['dataSurat'] = this.dataSurat.id
      var id_creator = this.dataSurat.hruserpositions_createdBy.id ? this.dataSurat.hruserpositions_createdBy.id : 0

      if(vm.isEdit){
        if(vm.seldaftarnama!=null){
          // console.log('seldaftarnama', vm.seldaftarnama)
          vm.daftarnamaForm['id'] = vm.seldaftarnama.id
          vm.daftarnamaForm['hruserpositions_pegawai'] = vm.seldaftarnama.hruserpositions_pegawai
          vm.daftarnamaForm['arr_hruserpositions_daftarnama'] = vm.seldaftarnama.arr_hruserpositions_daftarnama
          vm.daftarnamaForm['unit_instansi'] = vm.seldaftarnama.unit_instansi
          vm.daftarnamaForm['dataSurat'] = vm.seldaftarnama.dataSurat
          vm.daftarnamaForm['daftarNama'] = vm.seldaftarnama.daftarNama
          vm.daftarnamaForm['unit_instansi'] = vm.seldaftarnama.unit_instansi
          vm.daftarnamaForm['pegawaiName'] = vm.seldaftarnama.pegawaiName
          vm.daftarnamaForm['pegawaiOrg'] = vm.seldaftarnama.pegawaiOrg
          vm.daftarnamaForm['pegawaiPrefix'] = vm.seldaftarnama.pegawaiPrefix

          var dateStart = vm.seldaftarnama.dateStart
          if (dateStart != '0000-00-00') {
            dateStart = moment(dateStart).format('YYYY-MM-DD')
          }
          var dateEnd = vm.seldaftarnama.dateEnd
          if (dateEnd != '0000-00-00') {
            dateEnd = moment(dateEnd).format('YYYY-MM-DD')
          }
          vm.daftarnamaForm['dateStart'] = dateStart
          vm.daftarnamaForm['dateEnd'] = dateEnd

          if(vm.daftarnamaForm.hruserpositions_pegawai > 0){
            vm.daftarnamaForm.arr_hruserpositions_daftarnama = vm.daftarnamaForm.hruserpositions_pegawai
            vm.daftarnamaForm.unit_instansi = vm.daftarnamaForm.unit_instansi
          }else{
            vm.daftarnamaForm.arr_hruserpositions_daftarnama = vm.daftarnamaForm.daftarNama
            vm.daftarnamaForm.unit_instansi = vm.daftarnamaForm.unit_instansi
          }
          vm.initStatusYth()

          vm.daftarnamaForm.dateStart = vm.daftarnamaForm.dateStart
          vm.daftarnamaForm.dateEnd = vm.daftarnamaForm.dateEnd

          
        }
      }else{
    
        //If konseptor
        var unitcode = vm.currrole.hrorganization.unit_code
        var role = vm.currrole.rolename

        //'admin', 'developer', 'staff', 'sekretaris', 'kadiv', 'kasat', 'direktur', 'kpa', 'ppk', 'staff_ppk'
        unitcode = unitcode.toString()
        if(role == 'direktur'){
          unitcode = unitcode.substring(0, 2)
        }else if(role == 'kadiv' || role == 'kasat'){
          unitcode = unitcode.substring(0, 3)
        }
        // console.log(unitcode)
        // console.log(id_creator + '==' + myupos)
        // if(id_creator == myupos || unitcode == '1000'){
          // this.getUsersData()
        // }else{
          // If Other use Unit Code

          // console.log('If Other use Unit Code')
          // this.getUsersDataByUnit(unitcode)
        // }
      }
    }
    
  },

  methods: {
    resetForm () {
      this.$refs.form.reset()
    },
    doCancel(){
      //  // console.log('back')
      this.$emit('dialogPegawaiClose', true)
    },
    parseUser() {
      const vm = this
      var rData = secureStorage.getItem('userposall')
      // console.log(rData)
      if (rData !== null) {
        let temp = []
        rData.forEach(element => {
          if (element != null) {
            if (element.rolename != 'admin' && element.rolename != 'developer' && element.rolename != 'admin_sdm') {
              // console.log(element)
              if (element.prefix != null) { element.prefix = element.prefix + ' ' } else { element.prefix = '' }

              let uposid = element.id
              element['posname'] = element.prefix + element.pos_name
              element['userposname'] = element.fullname + ' (' + element['posname'] + ')'
              element['unitcode'] = parseInt(element.unit_code)

              let tmp = {text:  element['userposname'], value: uposid, unitcode: element['unitcode']}
              temp.push(tmp)
            }
          }
        })
        
        temp.sort((a, b) => Number(a.unitcode) - Number(b.unitcode))
        vm.dataListUserposall = temp
        // console.log(vm.dataListUserposall)
      }
      
    },

    initStatusYth(){
      const vm = this
      var datauserposall = vm.dataListUserposall
      let tos = vm.daftarnamaForm.arr_hruserpositions_daftarnama
      // console.log('tos',tos)
      if(tos){
        tos = tos.toString()
        vm.arrhruserpositions_to = []
        // let arrtos =splitComponentsByComma(tos)
        let arrtos = tos.split('|')
    
        if(arrtos.length > 0 && datauserposall.length > 0){
          // console.log('initStatusYth', arrtos)
          arrtos.forEach(idtos => {
            let isIntid =  parseInt(idtos)
            
            if(isIntid > 0){
              let tmp = datauserposall.find(x => x.value === isIntid)
              if(tmp){
                vm.arrhruserpositions_to.push(tmp)
              }
            }else{
              if(idtos != ''){
                vm.arrhruserpositions_to.push(idtos)
              }
            }
          })
        }
      }
      // console.log('initStatusYth', vm.arrhruserpositions_to)
    },
    cekStatusYth(){
      const vm = this
      var array = vm.arrhruserpositions_to
       // console.log('cekStatusYth',array)
      let tmp = []
      if(array){
        array.forEach(element => {
          if(element){
            if(element.value){
              tmp.push(element.value)
            }else if(element != ''){
              tmp.push(element)
            }
          }
        });
      }
      
      vm.daftarnamaForm.arr_hruserpositions_daftarnama = tmp
      // console.log(vm.daftarnamaForm.arr_hruserpositions_daftarnama)
    },
    validationdate(value){
      var dateval = moment(value)
      var eostart = moment(this.dataSurat.st_dateStart)
      var eoend = moment(this.dataSurat.st_dateEnd)
      var one_day = false
      if(eostart.isSame(eoend)){
        one_day = true
      }

      // console.log('one_day: ',one_day)
      // console.log('dateval.isAfter(eoend): ',dateval.isAfter(eoend))
      // console.log('dateval.isBefore(eostart): ',dateval.isBefore(eostart))

      if(one_day == false){
        if (dateval.isAfter(eoend) || dateval.isBefore(eostart)) {
          this.toast = {
            show: true, color:'red', text: 'Tanggal tidak sesuai, Silakan cek kembali tanggal yang dipilih harus sesuai dengan tanggal Acara pada Surat Tugas.', timeout: 2000
          }
          return false
        } else {
          return true
        }
      }else{
        if (!dateval.isSame(eostart) || !dateval.isSame(eoend)) {
          this.toast = {
            show: true, color:'red', text: 'Tanggal tidak sesuai, Silakan cek kembali tanggal yang dipilih harus sesuai dengan tanggal Acara pada Surat Tugas.', timeout: 2000
          }
          return false
        } else {
          return true
        }
      }
      
    },
    saveForm () {
      const vm = this
      if(vm.validationdate(vm.daftarnamaForm.dateStart) && vm.validationdate(vm.daftarnamaForm.dateEnd)){
        if(vm.$refs.form.validate()){
          // console.log(vm.postForm)
          vm.dialogConfirm = true
        }
        else{
          vm.toast = {
            show: true, color:'red', text: 'Form still has an error!', timeout: 2000
          }
        }
      }
      
    },
    submitForm() {
      // console.log('submitForm')
      const vm = this

      vm.dialogConfirm = false
      vm.isLoading = true

      vm.daftarnamaForm.hruserpositions_Creator = vm.currrole.id
      vm.daftarnamaForm.datasurat = vm.dataSurat.id

      if(this.isEdit){

        var data = {
          dateStart: vm.daftarnamaForm.dateStart,
          dateEnd: vm.daftarnamaForm.dateEnd
        }
        // console.log(data)
        // console.log(vm.daftarnamaForm.id)
        updateDaftarPegawaiST(data, vm.daftarnamaForm.id).then(response => {
          var resp = response.data
          // console.log(response.data)
          if (resp[0].id > 0) {
            vm.daftarnamaForm.id = resp.id
            vm.toast = {
              show: true, color:'green', text: 'Ubah Daftar Pelaksana Tugas BERHASIL.', timeout: 2000
            }
            vm.daftarnamaForm.arr_hruserpositions_daftarnama = ''
            vm.daftarnamaForm.dateStart = ''
            vm.daftarnamaForm.dateEnd = ''
            vm.daftarnamaForm.unit_instansi = ''
            
            vm.$emit('dialogPegawaiClose', true)
          } else {
            vm.toast = {
              show: true, color:'red', text: 'Ubah Daftar Pelaksana Tugas GAGAL, silakan dicoba kembali.', timeout: 2000
            }
          }
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
          vm.toast = {
            show: true, color:'red', text: 'Ubah Daftar Pelaksana Tugas GAGAL, silakan dicoba kembali.', timeout: 2000
          }
        })
      }else{
        insertDaftarPegawaiST(vm.daftarnamaForm).then(response => {
          var resp = response.data[0]
          // console.log(response.data)
          if (resp.id > 0) {
            vm.daftarnamaForm.id = resp.id
            vm.toast = {
              show: true, color:'green', text: 'Submit Daftar Pelaksana Tugas BERHASIL.', timeout: 2000
            }
            vm.daftarnamaForm.arr_hruserpositions_daftarnama = ''
            vm.daftarnamaForm.dateStart = ''
            vm.daftarnamaForm.dateEnd = ''
            vm.daftarnamaForm.unit_instansi = ''
            
            vm.$emit('dialogPegawaiClose', true)
          } else {
            vm.toast = {
              show: true, color:'red', text: 'Submit Daftar Pelaksana Tugas GAGAL, silakan dicoba kembali.', timeout: 2000
            }
          }
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
          vm.toast = {
            show: true, color:'red', text: 'Submit Daftar Pelaksana Tugas GAGAL, silakan dicoba kembali.', timeout: 2000
          }
        })
      }
    }
      
  }
}

</script>

<style>

.v-autocomplete__content.v-menu__content{
  z-index: 300 !important;
}

.v-input--selection-controls {
    margin: 0;
    padding: 0; 
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.7) !important;
}

</style>
